<style scoped lang="less">
.ant-input-number-affix-wrapper {
  width: 100%;
}
</style>
<script lang="tsx">
import { Col, Form, FormItemProps } from 'ant-design-vue/es'
import { omit, pick, isFunction } from 'lodash-es'
import { computed, defineComponent, PropType } from 'vue'
import { componentTypeMap } from './component-type-map'
import { useFormContext } from '../hooks/context'
import { createDefaultMsg, createSchemaRule } from '../hooks/rule'
import { useItemLabelWidth } from '../hooks/use-label-width'


export default defineComponent({
  props: {
    dynamicRules: {
      type: Array,
      default: () => []
    },
    // 是否是动态表单
    isDynamic: {
      type: Boolean,
      default: false
    },
    // 动态表单数组下标
    dynamicIndex: {
      type: [Number, String],
    },
    // 动态表单的父字段
    dynamicParentField: {
      type: String,
      default: ''
    },
    schema: {
      type: Object as PropType<IForm.Schema>,
      required: true,
    },
    formProps: {
      type: Object as PropType<IForm.Props>,
      default: () => ({}),
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:dynamicValue'],
  setup(props, { emit }) {
    const { formProps } = props
    const formContext = useFormContext()
    const itemLabelWidthProp = useItemLabelWidth(props.schema, props.formProps, formProps.layout)
    const { labelCol, wrapperCol } = unref(itemLabelWidthProp)

    // 是否允许折叠
    const isNotCollapsed = computed(() => !props.schema.collapsible || !props.collapsed)

    // 是否显示
    const isShow = computed((): boolean => {
      const { isShow = true } = props.schema

      if (isFunction(isShow)) {
        let schemaParams = {
          schema: props.schema,
          field: props.schema.field!,
          values: { ...formContext.formModel },
          dynamicIndex: props.dynamicIndex,
        } as any
        if (props.isDynamic && props.dynamicParentField && props.dynamicIndex) {
          schemaParams = {
            ...schemaParams,
            values: { ...formContext.formModel[props.dynamicParentField][props.dynamicIndex] },
            dynamicParentField: props.dynamicParentField,
            dynamicIndex: props.dynamicIndex,
          }
        } else {
          schemaParams.values = { ...formContext.formModel }
        }
        return isShow(schemaParams)
      } else {
        return isShow
      }
    })

    // 操作项数据更新回调函数
    function handleUpdateValue(value) {
      if (props.isDynamic) {
        // emits('update:dynamicValue', value)
        emit('update:dynamicValue', value)
      } else {
        formContext.setFormModel({ [props.schema.field!]: value })
        formContext.validate([props.schema.field!])
      }
    }

    let span = props.isDynamic
      ? props.schema.span ?? props.formProps.baseItemCol?.span
      : props.schema.span
    // col 容器配置
    const colAttrs = computed(() => ({
      span,
      ...pick(props.schema, ['span']),
    }))

    // formItem 属性配置
    const formItemAttrs = computed(() => ({
      name: props.isDynamic ? props.dynamicRules : props.schema.field,
      rules: createSchemaRule(props.schema),
      labelCol,
      wrapperCol,
      // labelCol: { style: { width: formProps.layout !== 'vertical' ? `${formProps.labelWidth}px` : '100%' } },
      // wrapperCol: { style: { width: formProps.layout !== 'vertical' ? `calc(100% - ${formProps.labelWidth}px)` : '100%' } },
      ...omit(props.schema, ['name', 'component', 'componentProps', 'defaultValue', 'span', 'rules', 'required']),
      validateTrigger: '',
    } as FormItemProps))

    // 监听 formModel（即本组件对应的 model 值变化）的变化，重置组件内容，如重置组件的 value 等属性
    const comAttrs = computed(() => {
      let { component: componentName, componentProps } = props.schema

      // 组件的属性是否为外部回调函数
      if (isFunction(componentProps)) {
        componentProps = componentProps({
          schema: props.schema,
          values: { ...formContext.formModel },
          field: props.schema.field,
          actions: omit(formContext, ['formModel']),
          dynamicIndex: props.dynamicIndex
        })
      }

      // 检查本组件是否是勾选类型的组件
      const isCheckComponent = componentName && ['Switch', 'Checkbox'].includes(componentName)
      return {
        allowClear: true, // 是否允许清除
        placeholder: createDefaultMsg(props.schema), // placeholder 提示语
        fieldName: props.schema.field, // 字段名
        ...componentProps, // 其他配置项
        disabled: formContext.isReadOnly.value || componentProps?.disabled, // todo
        [isCheckComponent ? 'checked' : 'value']: setValue(), // 监听 form model 中，本组件的数据变化，重置组件的值
        [isCheckComponent ? 'onUpdate:checked' : 'onUpdate:value']: handleUpdateValue,
      }
    })

    function setValue() {
      if (props.dynamicIndex !== undefined && props.dynamicParentField) {
        return formContext.formModel[props.dynamicParentField][props.dynamicIndex][props.schema.field]
      } else {
        return formContext.formModel[props.schema.field!] // 监听 form model 中，本组件的数据变化，重置组件的值
      }
    }
    // 渲染具体组件的函数
    function renderCom() {
      const { component = 'Input', customComponent, renderComponentContent } = props.schema
      let Com: ReturnType<typeof defineComponent> = null
      if (customComponent) {
        Com = toRaw(customComponent) // 转化为普通对象，避免响应式依赖，频繁变动
      } else {
        Com = componentTypeMap[component] as ReturnType<typeof defineComponent>
      }

      return renderComponentContent ? (
        <Com {...comAttrs.value} v-slots={{
          default: () => renderComponentContent({ dynamicIndex: props.dynamicIndex })
        }}></Com>
      ) : (
        <Com {...comAttrs.value} />
      )
    }

    return () => (
      isShow.value && (
        <Col {...colAttrs.value} v-show={isNotCollapsed.value}>
          <Form.Item {...formItemAttrs.value}>{renderCom()}</Form.Item>
        </Col>
      ))
  },
})
</script>
