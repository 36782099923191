<template>
  <div>
    <div v-if="isTableMode">
      <a-table
        v-bind="props.tableProps"
        :data-source="dataSource"
        :columns="columns"
        :pagination="false"
        class="rounded overflow-hidden"
        bordered
        table-layout="fixed"
        size="small"
      >
        <template #headerCell="{ column, title }">
          <span
            v-if="getRequired(column.dataIndex)"
            class="text-red-500 text-[16px] mr-[1px]"
          >*</span>
          <span class="text-[14px]">{{ title }}</span>
        </template>
        <template #bodyCell="{ column, index }">
          <div
            v-if="column.dataIndex == 'action'"
            class="flex items-center ml-[12px]"
          >
            <plus-circle-outlined
              v-if="index === dynamicSchemas.length - 1 && (props.maxCount===undefined || (props.maxCount!==undefined && dynamicModel?.length < props.maxCount))"
              class="text-[16px] cursor-pointer mr-3"
              @click="add"
            />
            <template v-if="needUpDown">
              <arrow-down-outlined
                v-if="index !== dynamicSchemas.length - 1"
                class="text-[16px] cursor-pointer mr-3"
                @click="downFn(index)"
              />
              <arrow-up-outlined
                v-if="index !== 0"
                class="text-[16px] cursor-pointer mr-3"
                @click="upFn(index)"
              />
            </template>

            <MinusCircleOutlined
              v-if="index > 0"
              class="text-[16px] cursor-pointer"
              @click="removeFn(index)"
            />
          </div>
          <div
            v-else
            class="flex w-full"
          >
            <form-item
              v-if="getSchema(dynamicSchemas[index], column.dataIndex)"
              :key="getSchema(dynamicSchemas[index], column.dataIndex)?.field + index"
              v-model:dynamicValue="dynamicModel[index][column.dataIndex]"
              :dynamic-rules="[dynamicParentField, index, column.dataIndex]"
              :is-dynamic="true"
              :dynamic-parent-field="dynamicParentField"
              :form-props="formProps"
              :dynamic-index="`${index}`"
              span="24"
              :schema="getSchema(dynamicSchemas[index], column.dataIndex)"
            />
          </div>
        </template>
      </a-table>
    </div>
    <div v-if="!isTableMode">
      <div
        v-for="(item, ix) in dynamicSchemas"
        :key="ix"
        class="relative flex flex-wrap"
      >
        <form-item
          v-for="schema in item"
          :key="schema.field"
          v-model:dynamicValue="dynamicModel[ix][schema.field]"
          class="mt-2 mr-2"
          :dynamic-rules="[dynamicParentField, ix, schema.field]"
          :is-dynamic="true"
          :dynamic-parent-field="dynamicParentField"
          :form-props="formProps"
          :dynamic-index="`${ix}`"
          :schema="schema"
        />
        <MinusCircleOutlined
          v-if="dynamicSchemas.length !== 1"
          class="absolute right-0 ml-2 bottom-[10px]"
          @click="remove(ix)"
        />
      </div>
      <a-form-item class="mt-4">
        <a-button
          type="dashed"
          block
          @click="add"
        >
          <PlusCircleOutlined />
          添加项
        </a-button>
      </a-form-item>
    </div>
  </div>
</template>
<script lang="tsx" setup>
import { cloneDeep, isArray, isFunction } from 'lodash-es'
import { dynamicFormProps } from './props'
import { useAttrs } from 'vue'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons-vue'
import { createDefaultMsg, createSchemaRule } from '@/components/form/hooks/rule'
import { useFormContext } from '@/components/form/hooks/context'
import { useMessage } from '@/hooks/message'
const formContext = useFormContext()
const props = defineProps(dynamicFormProps)
const attrs = useAttrs()
const addFn = (index: number) => {
  if (index > 0 && index < dynamicModel.value?.length) {
    dynamicModel.value.splice(index, 1)
  }
}
const clearValidateFn = () => {
  // const fields = dynamicSchemas.value?.map(item => {
  //   if (Array.isArray(item)) {
  //     return item.map((i, idx) => `${dynamicParentField.value}${idx}${i.field}`)
  //   }
  //   return item
  // })?.flat()
  // console.log('fields', fields)
  // todo 清楚特定字段校验
  // formContext.clearValidate(fields)
  formContext.clearValidate()
}
const removeFn = (index: number) => {
  if (index > 0 && index < dynamicModel.value?.length) {
    dynamicModel.value.splice(index, 1)
  }
}
const upFn = (index: number) => {
  if (index < 1) {
    return
  }
  let temp = dynamicModel.value[index]
  dynamicModel.value[index] = dynamicModel.value[index - 1]
  dynamicModel.value[index - 1] = temp
  clearValidateFn()
}
const downFn = (index: number) => {
  if (index >= dynamicModel.value?.length - 1) {
    return
  }
  let temp = dynamicModel.value[index]
  dynamicModel.value[index] = dynamicModel.value[index + 1]
  dynamicModel.value[index + 1] = temp
  clearValidateFn()
}
const getRequired = dataIndex => {
  if (Array.isArray(props.itemSchemas)) {
    let findItem = props.itemSchemas.find(item => item.field === dataIndex)
    if (findItem) {
      return findItem.required
    }
  }
  return false
}

const getSchema = (schemas: IForm.Schema[], field: string) => {
  if (Array.isArray(schemas)) {
    let findItem: any = schemas.find(item => item.field === field)
    if (findItem) {
      if (findItem.componentProps && !findItem.componentProps.placeholder) {
        // 避免多次触发覆盖
        findItem.componentProps.placeholder = createDefaultMsg(findItem)
      }
      findItem.rules = createSchemaRule(findItem)
      findItem.label = ''
      return findItem
    }
  }
  return null
}

const dataSource = computed(() => dynamicModel.value)
const columns = computed(() => {
  let schemaColumns: any = []
  if (Array.isArray(props.itemSchemas)) {
    schemaColumns = props.itemSchemas.map((item, index) => ({
      ...item,
      title: item.label,
      key: item.field,
      dataIndex: item.field,
      rowSpan: 12,
      // 坑，formItemWidthClass换成className有问题
      className: item.formItemWidthClass ? item.formItemWidthClass : undefined
    }))
  }
  return [
    ...schemaColumns,
    {
      title: ' ',
      key: 'action',
      dataIndex: 'action',
      className: 'w-[110px]',
    },
  ]
})
// const dynamicModel = ref<Recordable[]>(props.value || [])
const dynamicParentField = computed(() => (attrs.fieldName as string) ?? '')
const dynamicModel = computed(() => {
  if (isArray(props.value) && props.value.length > 0) {
    return props.value
  } else {
    let temp = {}
    props.itemSchemas.forEach((item, index) => {
      temp[item.field] = undefined
    })
    return [temp]
  }
})

const addModel = () => {
  let temp = {}
  props.itemSchemas.forEach(item => {
    if (isFunction(item.defaultValue)) {
      temp[item.field] = item.defaultValue()
    } else {
      temp[item.field] = item.defaultValue ?? undefined
    }
  })
  dynamicModel.value.push(temp)
}
const dynamicSchemas = computed(() => dynamicModel.value.map(i => cloneDeep(props.itemSchemas)))
const emits = defineEmits(['update:value'])
emits('update:value', props.indexFieldName ? dynamicModel.value?.map((item, index) => (Object.assign(item, { [props.indexFieldName]: index + 1 }))) : dynamicModel.value)
const add = () => {
  if (props.maxCount !== undefined && dynamicModel.value?.length >= props.maxCount) {
    useMessage.info(`最多添加${props.maxCount}条`)
    return
  }
  dynamicSchemas.value.push(cloneDeep(props.itemSchemas))
  addModel()
}
const remove = index => {
  if (dynamicModel.value?.length) {
    dynamicModel.value.splice(index, 1)
  }
}
</script>
